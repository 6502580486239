<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        title="Activate and deactivate Chatpatterns"
      >

        <!--      <b-card-text>Delete/Edit chat patterns.</b-card-text>-->

        <!-- toggle button -->
        <b-col cols="4">
          <b-list-group>
            <b-list-group-item
              v-for="(item) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
              :name="item.name"
            >
              {{ item.name }}
              Anzahl der Nachrichten
              {{ item.textlength }}
              <b-form-checkbox
                v-model="item.active"
                class="custom-control-success"
                name="check-button"
                switch
                @change="changeStatus(item.name, item.active , item.uid)"
              />

            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            Please buy a license
          </p>
        </div></template>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable prefer-template,vue/no-unused-components */

import {
  BFormTags,
  BForm,
  BFormFile,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
  // BFormFile,
  BButton,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  VBTooltip,
  BCollapse,
  BFormCheckbox,
  VBToggle,
  BFormTextarea, BIcon, BOverlay, BIconCash,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import firebase from 'firebase'
import { getUserData } from '@/auth/utils'
import { codeBasic, codemultipleChoice } from './code'

// import db from '../db'
export default {
  components: {
    BCol,
    BRow,
    BOverlay,
    BFormInput,
    BFormTags,
    BButton,
    BCardText,
    // BFormFile,
    BFormGroup,
    BForm,
    BCard,
    BFormCheckbox,
    BCollapse,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BIcon,
    BFormCheckboxGroup,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      testCollection: [],
      edit: false,
      onEditIndex: null,
      onEditName: null,
      show: false,
      name: null,
      filetext: null,
      file: null,
      file2: null,
      general: false,
      nextTodoId: 1,
      value: ['Hi', 'How are you?', 'Great'],
      selected: [],
      options: [
        { text: 'General', value: 'General' },
        { text: 'Stream starting', value: 'StreamStarting' },
        { text: 'Mario Kart', value: 'MarioKart' },
        { text: 'Just Chatting', value: 'JustChatting' },
        { text: 'Marbles', value: 'Marbles' },
        { text: 'League of Legends', value: 'League' },
        { text: 'Valorant', value: 'Valorant' },
      ],
      items: [],
      codeBasic,
      codemultipleChoice,
    }
  },
  watch: {
    value(val) {
      this.filetext = val.join('\n')
    },
    filetext(val) {
      this.value = val.split('\n')
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    // Get Existing Chat pattern
    const userData = getUserData()
    // console.log(userData.id)
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        }
      })
    sx.collection('User')
      .doc(userData.id)
      .collection('Chatlists')
      .get()
      .then(snap => {
        // console.log(snap.docs[0].data())
        // console.log('SNAPDOC' + snap.get())
        snap.forEach(doc => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, ' => ', doc.data())
          // const test = doc.data()
          this.items.push({
            id: this.nextTodoId += 1,
            name: doc.data().name,
            active: doc.data().active,
            uid: doc.id,
            textlength: doc.data().text.length,
          })
          // eslint-disable-next-line no-restricted-syntax,array-callback-return
          // Object.keys(test).map(key => {
          //   console.log(`KEY${key}`)
          //   this.items.push({
          //     id: this.nextTodoId += 1,
          //     name: key,
          //   })
          // })
        })
      })
    // Aus Public lesen
  },
  methods: {
    changeStatus(name, activebool, uid) {
      console.log(activebool)
      console.log('Start')
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .doc(uid)
        .update(
          { active: activebool },
        )
    },
    tesst() {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', 'Heyho')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            const test = doc.data()
            // console.log(test)
            this.selected = test.Category
            this.value = test.text
            this.name = test.name
            // console.log(doc.id, ' XX=> ', doc.data())
          })
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
    },
    selectedFile() {
      // console.log('selected a file')
      // console.log(this.$refs.myFile.files[0])
      //
      // const file = this.$refs.myFile.files[0]
      // console.log(this.$refs.myFile.files[0])
      console.log(this.file)
      const { file } = this.file
      if (!file || file.type !== 'text/plain') return

      // Credit: https://stackoverflow.com/a/754398/52160
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      console.log('TESST')
      console.log(reader)
      reader.onload = evt => {
        this.filetext = evt.target.result
        this.value = evt.target.result.split('\n')
        // eslint-disable-next-line no-global-assign,no-restricted-globals
        // this.name = evt.target.result
        console.log('OUTPUTTTT')
        console.log(evt.target.result)
      }
      reader.onerror = evt => {
        console.log('ERROR')
        console.error(evt)
      }
    },
    updateRecord() {
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc('M2SjHXgnSJXaRI2UgmwN')
        .get()
        .then(snapshot => {
          const document = snapshot.data()
          // console.log(document.data())
          return document
          // do something with document
        })
        .catch(error => {
          console.log(error)
        })
    },
    editItem(name, index) {
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', name)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            const test = doc.data()
            // console.log(test)
            this.selected = test.Category
            this.value = test.text
            this.name = test.name
            // console.log(doc.id, ' XX=> ', doc.data())
          })
          this.edit = true
          this.onEditIndex = index
          this.onEditName = name
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index, name) {
      this.items.splice(index, 1)
      const userData = getUserData()
      const db = this.$firebase.firestore()
      db.collection('User')
        .doc(userData.id)
        .collection('Chatlists')
        .where('name', '==', name)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id)
            db.collection('User')
              .doc(userData.id)
              .collection('Chatlists')
              .doc(doc.id)
              .delete()
              .then(() => {
                console.log('Deleted document')
              })
          })
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    insertRecord() {
      if (this.edit) {
        // console.log(this.onEditIndex, this.onEditName)
        this.removeItem(this.onEditIndex, this.onEditName)
      }
      // const userData = getUserData()
      const db = this.$firebase.firestore()
      const citiesRef = db.collection('User')
      // eslint-disable-next-line no-plusplus
      citiesRef.doc(firebase.auth().currentUser.uid).collection('Chatlists').add({
        name: this.name,
        text: this.value,
        Category: this.selected,
      })
      // eslint-disable-next-line no-unused-vars
        .then(docref => {
          this.items.push({
            id: this.nextTodoId += 1,
            name: this.name,
          })
          // console.log('Collection Created')
          // console.log(docref.id)
          this.$swal({
            title: 'Succeed!',
            text: ' New Chatlist added!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          console.error('Error adding document: ', error)
        })
      if (this.edit) {
        this.edit = false
      }
      // this.value = []
      // this.name = null
    },
  },
}
</script>
